<script>
  import { get, call } from 'vuex-pathify'
  import ProductTile from './ProductTile.vue'

  /*
   * The slider component used in the Essentials and Choice Plus sections of the app
   */
  export default {
    name: 'ProductSlider',
    components: {
      ProductTile,
    },
    props: {
      /** An array of product objects. See `ProductCard` for detailed information on object properties. */
      products: {
        type: Array,
        required: true,
      },
      /** The number of slides per view. If not set, it will be determined automatically based on the slides’ width. */
      slidesPerView: {
        type: Number,
        default: undefined,
      },
      /** Whether there should be a quick add button on the product. */
      hasQuickAddButton: {
        type: Boolean,
        default: false,
      },
      /** Where this slider is being used. */
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['essentials', 'choice-plus', 'upsell'].includes(value),
      },
    },
    computed: {
      ...get('essentialsCart', ['isMaxInCart']),
    },
    methods: {
      ...call('essentialsCart', ['addToCart']),
    },
  }
</script>

<template>
  <div>
    <BaseHeading v-if="$scopedSlots.heading" size="h5" tag="h2" class="mb-2 md:mb-6 pr-12">
      <slot name="heading" />
    </BaseHeading>

    <!-- for purgecss: slider--modal -->
    <BaseSlider
      has-navigation
      :slides-per-view="slidesPerView"
      :class="`slider ${type === 'choice-plus' ? 'slider--choice-plus' : ''}`"
    >
      <BaseSliderSlide v-for="(product, index) in products" :key="index" class="slide">
        <ProductTile
          :type="type"
          :product="product"
          is-slide
          :has-quick-add-button="hasQuickAddButton"
          :is-max-in-cart="type === 'essentials' ? isMaxInCart(product.defaultVariant.id) : false"
          @addToCart="addToCart"
          @click="$emit('selectProduct', product)"
        />
      </BaseSliderSlide>
    </BaseSlider>
  </div>
</template>

<style lang="postcss" scoped>
  .slider {
    & >>> {
      & > .swiper-container {
        @apply overflow-hidden;
      }

      .swiper-button-prev,
      .swiper-button-next {
        @apply border-1 border-black bg-dawn;
      }

      .swiper-button-prev {
        @apply left-0 -ml-1;
      }

      .swiper-button-next {
        @apply right-0 -mr-1;
      }
    }

    &--choice-plus {
      & >>> {
        .swiper-button-prev,
        .swiper-button-next {
          @apply -mt-14;

          @screen md {
            @apply -mt-17;
          }
        }
      }
    }
  }

  .slide {
    @apply flex flex-col w-1/2 h-auto px-1;

    @screen md {
      @apply w-1/4 px-2;
    }

    @screen lg {
      @apply w-1/5;
    }

    .slider--modal & {
      @screen md {
        @apply w-1/3;
      }

      @screen lg {
        @apply w-1/4;
      }
    }
  }
</style>
