<script>
  // TODO: duplicates packages/spa/src/components/essentials/EssentialsProduct.vue
  import { sync } from 'vuex-pathify'

  /*
   * A product tile used in the Essentials section of the app as well as Essentials and Choice Plus product sliders. Includes a title, image, kicker, price, and discount (computed).
   */
  export default {
    name: 'ProductTile',
    props: {
      /**
       * Contains all data about the product.
       * title/productTitle: string,
       * brand: string,
       * retailValue: number,
       * defaultVariant: {title: string, price: number, discount},
       * description: string,
       * tags: array of strings,
       * images/previewCardImages: array of strings (S3 URLs)
       */
      product: {
        type: Object,
        required: true,
      },
      /** Whether this card is being used in a slider. */
      isSlide: {
        type: Boolean,
        default: false,
      },
      /** Whether the card has a "Quick Add" or "Add to Waitlist" button on hover. */
      hasQuickAddButton: {
        type: Boolean,
        default: false,
      },
      /** Whether to block adding more to cart */
      isMaxInCart: {
        type: Boolean,
        default: false,
      },
      /** The grid-column-start property for this cell. */
      colStart: {
        type: Number,
        default: undefined,
        validator: (value) => [1, 2, 3, 4].includes(value),
      },
      /** Where this card is being used. */
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['essentials', 'choice-plus', 'upsell'].includes(value),
      },
    },
    data() {
      return {
        addedTimeout: null,
        addingToCartOrWaitlist: false,
        addedToCartOrWaitlist: false,
      }
    },
    computed: {
      ...sync('general', ['preventNavigation']),
      variant() {
        if (this.type === 'choice-plus') {
          return undefined
        }
        return this.product.defaultVariant
      },
      isSoldOut() {
        if (this.type === 'choice-plus') {
          return undefined
        }
        return this.variant.stock <= 0 && !this.variant.hasUnlimitedStock
      },
      percentOff() {
        if (this.type === 'choice-plus') {
          return undefined
        }
        const discountAmount = this.product.retailValue - this.variant.price
        return Math.round((discountAmount / this.product.retailValue) * 100)
      },
      hasClickListener() {
        return this.$listeners && this.$listeners.click
      },
      productTitle() {
        switch (this.type) {
          case 'choice-plus':
            return this.product?.productTitle
          default:
            return this.product?.title
        }
      },
      images() {
        switch (this.type) {
          case 'choice-plus':
            return this.product.previewCardImages
          default:
            return this.product.images
        }
      },
    },
    methods: {
      addToCart() {
        this.preventNavigation = true
        this.addingToCartOrWaitlist = true
        clearTimeout(this.addedTimeout)
        try {
          this.$emit('addToCart', { variantId: this.variant.id })
          this.addingToCartOrWaitlist = false
          this.addedToCartOrWaitlist = true
          this.addedTimeout = setTimeout(() => {
            this.addedToCartOrWaitlist = false
          }, 5000)
        } finally {
          this.addingToCartOrWaitlist = false
        }
      },
      addToWaitlist() {
        this.preventNavigation = true
        clearTimeout(this.addedTimeout)
        this.$emit('addToWaitlist', { productId: this.product.id })
        this.addedToCartOrWaitlist = true
        this.addedTimeout = setTimeout(() => {
          this.addedToCartOrWaitlist = false
        }, 5000)
      },
      addHandler() {
        return this.isSoldOut ? this.addToWaitlist() : this.addToCart()
      },
    },
  }
</script>

<template>
  <!-- for purgecss: col-start-1 col-start-2 col-start-3 col-start-4  -->
  <BaseCard
    price-last
    :class="`col-start-${colStart} ${hasClickListener ? 'cursor-pointer' : 'cursor-auto'}`"
    @click="$emit('click')"
  >
    <template v-if="images.length > 0" v-slot:image>
      <div class="group overflow-hidden">
        <BaseImage
          :src="images[0]"
          :alt="productTitle"
          :aspect-ratio="1"
          responsive="md"
          :sizes="{ default: '50vw', md: '25vw' }"
        />
        <div
          v-if="hasQuickAddButton"
          class="absolute inset-x-0 bottom-0 opacity-0 md:group-hover:opacity-100 md:group-hover:translate-y-0 transform transition duration-400 translate-y-full z-90"
        >
          <BaseButton
            :disabled="addingToCartOrWaitlist || (!isSoldOut && isMaxInCart)"
            :icon-size="4"
            height="short"
            color="black"
            @click="addHandler"
          >
            <template v-if="addedToCartOrWaitlist" v-slot:icon>
              <IconCheckmarkThick />
            </template>
            <template v-else-if="!addingToCartOrWaitlist && !addedToCartOrWaitlist" v-slot:icon>
              <IconPlus />
            </template>
            <span class="truncate">
              <span v-if="addingToCartOrWaitlist">Adding...</span>
              <span v-else-if="addedToCartOrWaitlist">Added!</span>
              <span v-else>{{ isSoldOut ? 'Add to waitlist' : 'Quick add' }}</span>
            </span>
          </BaseButton>
        </div>
      </div>
    </template>
    <template v-if="percentOff && !isSoldOut" v-slot:discount>
      <span :class="`${isSlide ? 'hidden md:block md:px-1 md:py-2px' : 'px-1 py-2px'}`">
        {{ percentOff }}% off
      </span>
    </template>
    <template v-if="isSoldOut" v-slot:soldOut>
      <span class="px-1 py-2px"> Out of Stock </span>
    </template>
    <template v-slot:kicker>
      <div class="text-black text-xs md:text-base font-semibold" data-cy="product-brand">
        {{ product.brand }}
      </div>
    </template>

    <template v-slot:title>
      <div
        class="text-black text-xs md:text-base lg:text-lg hover:underline pr-2 leading-close"
        data-cy="product-title"
        style="height: 2.8em"
      >
        {{ productTitle }}
      </div>
    </template>

    <template v-if="type === 'essentials'" v-slot:price>
      <div class="text-black text-xs md:text-base lg:text-lg mt-2">
        <span class="ml-auto font-semibold">{{ $formatPrice(variant.price) }}</span>
        <s v-if="product.retailValue && product.retailValue > variant.price" class="pl-2 ml-auto">{{
          $formatPrice(product.retailValue)
        }}</s>
        <span v-if="isSlide" class="text-xs md:hidden ml-2">{{ percentOff }}% off</span>
        <span class="w-10 flex-grow" />
      </div>
    </template>
  </BaseCard>
</template>
