<script>
  import { get, call } from 'vuex-pathify'
  import trapFocus from '@/helpers/trapFocus'
  import { MESSAGE_UNEXPECTED_ERROR } from '@/helpers/constants'
  import ProductSlider from '@/components/product/ProductSlider'
  import screen from '@/helpers/screen'
  import { events } from '@/helpers/gtm'

  export default {
    components: {
      ProductSlider,
    },
    async beforeRouteUpdate(to, from, next) {
      if ((await this.ensureProductExists()) === false) {
        if (this.type === 'choice-plus') {
          next({ name: 'CustomizeStart' })
        }
        if (this.type === 'essentials') {
          next({ name: 'EssentialsHome' })
        }
        return
      }
      // Reset some data and scroll to the top of the modal
      this.quantity = 1
      this.addingToCartOrWaitlist = false
      this.addedToCartOrWaitlist = false
      clearTimeout(this.addedTimeout)
      this.addedTimeout = null
      this.errorMessage = null
      await this.ensureProductContainerRefExists()
      this.productContainerRef.scrollIntoView({
        behavior: 'smooth',
      })
      next()
    },
    props: {
      productId: {
        type: Number,
        default: undefined,
      },
      variantId: {
        type: Number,
        default: undefined,
      },
      boxId: {
        type: Number,
        default: undefined,
      },
      type: {
        type: String,
        default: undefined,
        validator: (value) => ['essentials', 'choice-plus', 'upsell'].includes(value),
      },
      isProductSliderDisabled: {
        type: Boolean,
        default: false,
      },
    },
    data() {
      return {
        quantity: 1,
        addingToCartOrWaitlist: false,
        addedToCartOrWaitlist: false,
        addedTimeout: null,
        productContainerRef: null,
        errorMessage: null,
      }
    },
    computed: {
      ...get('config', [
        'currentSeason',
        'currentYear',
        'variantsById',
        'variantsByBoxId',
        'boxesById',
      ]),
      ...get('upsell', ['upsellProducts']),
      ...get('essentials', ['products', 'plpIsL1', 'plpIsL2']),
      ...get('customize', ['filteredSelectedBoxVariants', 'currentChoice']),
      screen,
      cart() {
        switch (this.type) {
          case 'choice-plus':
            return 'choicePlusCart'
          case 'essentials':
            return 'essentialsCart'
          case 'upsell':
            return 'upsell'
          default:
            return ''
        }
      },
      productDescriptionProperties() {
        return [
          // essentials
          {
            property: 'instructions',
            title: 'Details & Care',
          },
          {
            property: 'specs',
            title: `${this.type === 'essentials' ? 'Ingredients & Materials' : 'Details & Care'}`,
          },
          {
            property: 'disclaimer',
            title: 'Good to Know',
          },
          // choice-plus
          {
            property: 'careInstructions',
            title: 'Details & Care',
          },
          {
            property: 'materials',
            title: 'Ingredients & Materials',
          },
        ]
      },
      product() {
        switch (this.type) {
          case 'choice-plus':
            return this.variantsById?.[this.variantId]
          case 'upsell':
            return this.upsellProducts?.find((product) => product.id === this.productId)
          default:
            return this.products?.find((product) => product.id === this.productId)
        }
      },
      productTitle() {
        switch (this.type) {
          case 'choice-plus':
            return this.product?.productTitle
          default:
            return this.product?.title
        }
      },
      essentialsTags() {
        if (this.type === 'choice-plus') {
          return []
        }
        return (
          this.product?.essentialsTags?.reduce((allTags, tag) => {
            if (tag.length > 0) {
              allTags.push(tag.replace(/-/g, ' '))
            }
            return allTags
          }, []) ?? []
        )
      },
      defaultVariant() {
        if (!this.product || this.type === 'choice-plus') {
          return undefined
        }
        // If the defaultVariant is out of stock, return the first variant that isn't
        const defaultVariant = this.product?.defaultVariant
        if (!defaultVariant?.hasUnlimitedStock && defaultVariant?.stock === 0) {
          const firstVariantWithStock = this.product.variants.find((variant) => {
            return variant?.hasUnlimitedStock || variant.stock > 0
          })
          if (firstVariantWithStock) {
            return firstVariantWithStock
          }
        }
        return defaultVariant
      },
      inStock() {
        return (
          this.defaultVariant &&
          (this.defaultVariant?.hasUnlimitedStock || this.defaultVariant?.stock > 0)
        )
      },
      quantitySelectionOptions() {
        if (this.type === 'choice-plus') {
          return undefined
        }
        // Use the Variant's maxQuantity if it's set, otherwise default to 10 as the
        // ceiling. Cap the ceiling at 10 for any instance where maxQuantity is
        // greater than that.
        const ceiling = Math.min(this.defaultVariant?.maxQuantity || 10, 10)

        const max =
          this.defaultVariant &&
          this.defaultVariant?.stock < ceiling &&
          !this.defaultVariant?.hasUnlimitedStock
            ? this.defaultVariant?.stock
            : ceiling
        if (max <= 0) {
          return []
        }
        return [...Array(Math.min(max, this.maxQuantityForCart)).keys()].map((i) => i + 1)
      },
      percentOff() {
        if (!this.product || this.type === 'choice-plus') {
          return 0
        }
        const discountAmount = this.product.retailValue - this.defaultVariant?.price
        return Math.round((discountAmount / this.product.retailValue) * 100)
      },
      maxQuantityAvailable() {
        if (this.type === 'choice-plus') {
          return 0
        }
        const maxQuantity = this.defaultVariant?.maxQuantity ?? 10
        return Math.min(this.defaultVariant?.stock, maxQuantity)
      },
      isMaxInCart() {
        if (this.type === 'choice-plus') {
          return false
        }
        const id = this.defaultVariant?.id
        return this.$store.get(`${this.cart}/isMaxInCart`, id)
      },
      maxQuantityForCart() {
        if (this.type === 'choice-plus') {
          return undefined
        }
        const lineItems = this.$store.get(`${this.cart}/lineItems`)

        const cartItem = lineItems.find(
          (lineItem) => lineItem.variantId === this.defaultVariant?.id
        )
        if (cartItem && !this.defaultVariant?.hasUnlimitedStock) {
          if (!this.isMaxInCart) {
            return this.maxQuantityAvailable - cartItem.quantity
          }
          return 0
        }
        return this.maxQuantityAvailable
      },
      isAddToCartEnabled() {
        return !this.addingToCartOrWaitlist && this.inStock && !this.isMaxInCart
      },
      images() {
        switch (this.type) {
          case 'choice-plus':
            return this.product.previewCardImages
          default:
            return this.product.images
        }
      },
      sliderProducts() {
        const choicePlusVariants = this.boxId
          ? this.variantsByBoxId(this.boxId)
          : this.filteredSelectedBoxVariants
        const products =
          this.type === 'choice-plus'
            ? choicePlusVariants
            : this.$store.get(`${this.type}/firstTenProducts`)
        return products?.filter((product) => product.id !== this.product.id)
      },
      truncatedYear() {
        const year = this.boxId ? this.boxesById[this.boxId].year : this.currentYear
        return year.toString().slice(-2)
      },
      sliderHeading() {
        switch (this.type) {
          case 'choice-plus':
            return `Featured in ${
              this.boxId ? this.boxesById[this.boxId].season : this.currentSeason
            } Box '${this.truncatedYear}`
          default:
            return 'You might also like'
        }
      },
    },
    watch: {
      quantity() {
        this.errorMessage = null
      },
    },
    async created() {
      window.addEventListener('keydown', this.handleKeyDown)
      this.$store.set('general/increaseOpenModalCount')
      if ((await this.ensureProductExists()) === false) {
        this.closeModal()
      }

      events.viewedProduct({
        product: this.product,
        storeType: this.type,
      })
    },
    beforeDestroy() {
      window.removeEventListener('keydown', this.handleKeyDown)
      this.$store.set('general/decreaseOpenModalCount')
    },
    methods: {
      ...call('essentialsCart', {
        doAddToWaitlist: 'addToWaitlist',
      }),
      closeModal() {
        if (this.type === 'choice-plus') {
          if (this.$route.name === 'CustomizeDetails') {
            this.$navigate({ name: 'CustomizeStart' })
          } else if (this.$route.name === 'BoxArchiveDetails') {
            this.$navigate({ name: 'BoxArchive' })
          } else if (this.$route.name === 'CustomizeSummaryDetails') {
            this.$navigate({ name: 'CustomizeSummary', params: { ...this.$route.params } })
          } else if (this.$route.name === 'CustomizeOrderSummaryDetails') {
            this.$navigate({ name: 'CustomizeOrderSummary', params: { ...this.$route.params } })
          } else if (this.$route.name === 'CustomizeUpgradeDetails') {
            this.$navigate({ name: 'CustomizeUpgrade', params: { ...this.$route.params } })
          } else if (this.$route.name === 'AccountMyPastBoxesDetails') {
            this.$navigate({ name: 'AccountProfileSubscriptions' })
          } else {
            this.$navigate({
              name: 'CustomizeChoice',
              params: { ...this.$route.params, choice: this.currentChoice },
            })
          }
        } else if (this.type === 'upsell') {
          if (this.$route.name === 'SubscribeMarketCheckoutProduct') {
            this.$navigate({ name: 'SubscribeMarketCheckout' })
          } else {
            this.$navigate({ name: 'SubscribeMarket' })
          }
        } else if (this.type === 'essentials') {
          if (this.$route.name === 'EssentialsCartProduct') {
            this.$navigate({ name: 'EssentialsCart' })
          } else if (this.plpIsL1 || this.plpIsL2) {
            this.$navigate({
              name: 'EssentialsPLP',
              params: {
                ...this.$route.params,
              },
            })
          } else {
            this.$navigate({ name: 'EssentialsHome' })
          }
        }
      },
      async ensureProductExists() {
        if (!this.product) {
          if (this.type === 'essentials') {
            await this.$store.dispatch('essentials/load')
          } else if (this.type === 'choice-plus') {
            await this.$store.dispatch('customize/load')
          }
        }
        return this.product !== undefined
      },
      async ensureProductContainerRefExists() {
        await this.$waitForValue(() => this.productContainerRef)
      },
      handleKeyDown($event) {
        // If the escape key was pressed, close the modal
        if ($event.keyCode === 27) {
          this.closeModal()
          return
        }
        // Trap the focus inside the modal
        trapFocus($event, this.productContainerRef)
      },
      async addToCart() {
        this.errorMessage = null
        this.addingToCartOrWaitlist = true
        clearTimeout(this.addedTimeout)
        try {
          const params = {
            variantId: this.defaultVariant?.id,
            quantity: this.quantity,
          }
          this.$store.dispatch(`${this.cart}/addToCart`, params)
          this.addingToCartOrWaitlist = false
          this.addedToCartOrWaitlist = true
          this.addedTimeout = setTimeout(() => {
            this.addedToCartOrWaitlist = false
          }, 5000)
        } catch (error) {
          const errors = error?.data?.errors
          if (errors) {
            // First attempt to get the validation error.
            this.errorMessage = errors[Object.keys(errors)[0]]
          } else {
            // Otherwise default to the error message.
            this.errorMessage = error?.message || MESSAGE_UNEXPECTED_ERROR
          }
        } finally {
          this.addingToCartOrWaitlist = false
        }
      },
      async addToWaitlist() {
        clearTimeout(this.addedTimeout)
        await this.doAddToWaitlist({
          productId: this.product.id,
        })
        this.addedToCartOrWaitlist = true
        this.addedTimeout = setTimeout(() => {
          this.addedToCartOrWaitlist = false
        }, 5000)
      },
      viewProduct(product) {
        if (this.type === 'upsell') {
          this.$navigate({
            name: 'SubscribeMarketProduct',
            params: {
              productId: product.id,
              type: 'upsell',
            },
          })
        } else if (this.type === 'choice-plus') {
          this.$navigate({
            name: this.$route.name,
            params: {
              ...this.$route.params,
              variantId: product.id,
              type: 'choice-plus',
            },
          })
        } else {
          const routeName =
            this.plpIsL2 || this.plpIsL1 ? 'EssentialsProductPLP' : 'EssentialsProductHome'
          this.$navigate({
            name: routeName,
            params: {
              ...this.$route.params,
              productId: product.id,
              productSlug: product.slug,
              productTitle: product.title,
              type: 'essentials',
            },
          })
        }
      },
    },
  }
</script>

<template>
  <section class="z-40 md:z-60 fixed inset-0">
    <div class="absolute inset-0 -ml-16 opacity-80 bg-true-black" />
    <div class="absolute inset-0 mt-17 overflow-y-auto parent-scrollbar">
      <div class="flex min-h-full">
        <div class="absolute inset-0" @click="closeModal" />
        <div
          v-ref="productContainerRef"
          :class="`product-container ml-auto w-full md:w-4/5 max-w-300 pb-40 sm:pb-20 overflow-hidden ${
            type === 'choice-plus' ? 'bg-dawn-lt3' : 'bg-dawn'
          }  lg:pr-8`"
          @scroll="$event.target.scrollLeft = 0"
        >
          <BaseSpinner v-if="!product" overlay="absolute" />
          <template v-else>
            <!-- Back arrow for desktop -->
            <button
              type="button"
              :class="`z-80 fixed -ml-16 w-16 h-16 flex items-center justify-center ${
                type === 'choice-plus' ? 'bg-dawn-lt3' : 'bg-dawn'
              }  border-black border-1 transition duration-200 -mt-1`"
              aria-label="Back to products"
              @click="closeModal"
            >
              <BaseIcon :size="8">
                <IconArrowLeft />
              </BaseIcon>
            </button>

            <!-- Dismiss 'X' icon for mobile -->
            <BaseIconClickable
              v-if="!screen.md"
              label="Close"
              color="black"
              class="z-20 absolute right-0 mr-4 top-0 mt-4"
              :size="4"
              @click="closeModal"
            >
              <IconXThick />
            </BaseIconClickable>

            <!-- Image slider and product details -->
            <div
              :class="`flex flex-col lg:flex-row lg:overflow-y-auto product-details-scrollbar ${
                !isProductSliderDisabled ? 'lg:max-h-180' : ''
              }`"
            >
              <!-- Mobile brand, title, price (sits on top of image slider on mobile) -->
              <div v-if="!screen.md" class="flex flex-col items-center text-center px-8">
                <div
                  class="mt-10 font-bold text-xs uppercase leading-snug"
                  style="letter-spacing: 0.03em"
                >
                  {{ product.brand }}
                </div>
                <div class="mt-2">
                  {{ productTitle }}
                </div>
                <div v-if="type !== 'choice-plus'" class="mt-2 mb-4 flex items-center">
                  <span class="mr-2 font-semibold text-base md:text-lg">
                    {{ $formatPrice(defaultVariant.price) }}
                  </span>
                  <s
                    v-if="product.retailValue && product.retailValue > defaultVariant.price"
                    class="mr-4 text-base md:text-lg"
                  >
                    {{ $formatPrice(product.retailValue) }}
                  </s>
                  <span
                    v-if="percentOff > 0"
                    class="bg-black text-white px-1 text-center text-xs md:text-2xs md:font-semibold tracking-wide"
                  >
                    {{ percentOff }}% OFF
                  </span>
                </div>
                <div v-else-if="type === 'choice-plus'" class="mb-4">
                  <span v-if="product.msrp > 0">({{ $formatPrice(product.msrp) }} value)</span>
                </div>
              </div>
              <div class="lg:w-1/2">
                <BaseSliderWithGallery :images="images" :alt="productTitle" inner-gallery />
              </div>

              <!-- Product details -->
              <div class="lg:w-1/2 pt-6">
                <div class="px-4 lg:px-15">
                  <!-- Desktop brand, title, price (sits to the right of image slider on desktop) -->
                  <div v-if="screen.md">
                    <div
                      class="mb-1 font-bold md:font-semibold text-xs md:text-base leading-snug uppercase tracking-wide"
                    >
                      {{ product.brand }}
                    </div>
                    <h2 class="md:text-xl">
                      {{ productTitle }}
                    </h2>
                    <div v-if="type !== 'choice-plus'" class="mt-2 md:mt-6 flex items-center">
                      <span class="mr-2 font-semibold text-base md:text-lg">
                        {{ $formatPrice(defaultVariant.price) }}
                      </span>
                      <s
                        v-if="product.retailValue && product.retailValue > defaultVariant.price"
                        class="mr-4 text-base md:text-lg"
                      >
                        {{ $formatPrice(product.retailValue) }}
                      </s>
                      <span
                        v-if="percentOff > 0"
                        class="bg-black text-white px-1 text-center text-xs md:text-2xs md:font-semibold tracking-wide"
                      >
                        {{ percentOff }}% OFF
                      </span>
                    </div>
                    <div v-else-if="type === 'choice-plus'">
                      <span v-if="product.msrp > 0">({{ $formatPrice(product.msrp) }} value)</span>
                    </div>
                  </div>

                  <div class="flex flex-col-reverse md:flex-col">
                    <!-- Product description -->
                    <BaseRichText class="mt-6" font-size="xs" v-html="product.description" />

                    <!-- Qty + add to cart/waitlist button (only for essentials and upsell) -->
                    <div
                      v-if="type !== 'choice-plus'"
                      class="z-20 fixed inset-x-0 bottom-0 sm:static sm:inset-auto sm:mt-9 px-4 py-4 sm:p-0 border-t border-gray-active sm:border-0 bg-dawn"
                    >
                      <BasePanelWarning v-if="errorMessage" class="mb-4">
                        {{ errorMessage }}
                      </BasePanelWarning>
                      <div class="flex">
                        <div v-if="inStock" class="mr-4 w-24 flex-shrink-0">
                          <BaseInput
                            v-model="quantity"
                            type="select"
                            is-pdp-selector
                            :options="quantitySelectionOptions"
                            :disabled="!isAddToCartEnabled"
                          >
                            <span v-if="!isAddToCartEnabled">Qty</span>
                          </BaseInput>
                        </div>
                        <div class="flex-grow">
                          <BaseButton
                            :disabled="!isAddToCartEnabled"
                            :color="inStock ? 'primary' : ''"
                            @click="inStock ? addToCart() : undefined"
                          >
                            <span class="truncate">
                              <span v-if="addingToCartOrWaitlist">Adding...</span>
                              <span v-else-if="addedToCartOrWaitlist">Added!</span>
                              <span v-else>{{ inStock ? 'Add to cart' : 'Out of Stock' }}</span>
                            </span>
                          </BaseButton>
                        </div>
                      </div>
                    </div>

                    <!-- Essentials tags -->
                    <div v-if="essentialsTags.length > 0" class="flex flex-wrap mt-2 md:mt-8">
                      <div
                        v-for="(tag, tagIndex) in essentialsTags"
                        :key="tagIndex"
                        class="border-black border-1 rounded-full px-2 py-2px mr-1 mb-1 capitalize text-2xs"
                      >
                        {{ tag }}
                        <BaseIcon :size="3" class="inline-block align-middle">
                          <IconCheckmarkThick />
                        </BaseIcon>
                      </div>
                    </div>
                  </div>

                  <!-- Accordions with extra product information -->
                  <div class="mt-10 border-b-2 border-black">
                    <div
                      v-for="({ property, title }, propertyIndex) in productDescriptionProperties"
                      :key="propertyIndex"
                    >
                      <div v-if="product[property]" :class="`border-t-2 border-black`">
                        <BaseAccordion button-inner-classes="py-4">
                          <template v-slot:buttonLabel>
                            <span class="font-bold">{{ title }}</span>
                          </template>
                          <template v-slot>
                            <div class="pb-5">
                              <BaseRichText font-size="xs" v-html="product[property]" />
                            </div>
                          </template>
                        </BaseAccordion>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>

            <!-- Product slider -->
            <ProductSlider
              v-if="!isProductSliderDisabled && sliderProducts"
              :products="sliderProducts"
              :slides-per-view="screen.md ? 3 : 2"
              context="modal"
              :type="type"
              class="mt-8 md:mt-20 mx-3"
              @selectProduct="viewProduct($event)"
            >
              <template v-slot:heading>
                <span class="md:pl-2 text-xxl font-semibold font-heading ml-1 md:ml-0">
                  {{ sliderHeading }}
                </span>
              </template>
            </ProductSlider>
          </template>
        </div>
      </div>
    </div>
  </section>
</template>

<style lang="postcss">
  /* purgecss start ignore */
  .parent-scrollbar {
    /* width */
    &::-webkit-scrollbar {
      @apply w-0;
    }
  }

  .product-details-scrollbar {
    /* width */
    &::-webkit-scrollbar {
      @apply w-1;
    }

    /* Track */
    &::-webkit-scrollbar-track {
      @apply my-5 bg-gray-opacity68 rounded-full;

      @screen md {
        @apply mx-10;
      }

      @screen xl {
        @apply mx-20;
      }
    }

    /* Handle */
    &::-webkit-scrollbar-thumb {
      @apply bg-black rounded-full;
    }
  }
</style>
