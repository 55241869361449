/**
 * Prevents users from tabbing through elements outside a given container. This function needs to be called inside a `keydown` event handler.
 */

export default ($event, container) => {
  // Make sure the tab key was pressed
  if ($event.keyCode !== 9) {
    return
  }

  const focusedElement = document.activeElement
  const focusableElements = Array.from(
    container.querySelectorAll('button, a, textarea, input, select')
  )
  const firstFocusableElement = focusableElements[0] ?? null
  const lastFocusableElement = focusableElements[focusableElements.length - 1] ?? null

  // If there are no focusable elements in the container, prevent tabbing altogether
  if (focusableElements.length === 0) {
    $event.preventDefault()
    return
  }

  // If none of the container's focusable elements is focused, focus the first one
  if (!focusedElement || focusableElements.includes(focusedElement) === false) {
    firstFocusableElement.focus()
    $event.preventDefault()
    return
  }

  // If tab is pressed on the last focusable element, then return to the first one
  if (!$event.shiftKey && focusedElement === lastFocusableElement) {
    firstFocusableElement.focus()
    $event.preventDefault()
    return
  }

  // If shift + tab is pressed on the first focusable element, then focus the last one
  if ($event.shiftKey && focusedElement === firstFocusableElement) {
    lastFocusableElement.focus()
    $event.preventDefault()
  }
}
